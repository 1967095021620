export const getTermsOfUseModaltemplate = () => `
  <section class="modal" onclick="window.closeModal(event, this)">
    <div class="modal__container modal__container--wide">
      <div class="modal__scroll">
        <h1 class="title title--secondary">Пользовательское соглашение</h1>

        <div class="box">
          <p>Настоящее Пользовательское соглашение (далее — «Соглашение») представляет собой предложение пользователю сети Интернет (далее — «Пользователь») заключить соглашение об использовании сервисов (далее — «Сервисы») предусмотренных на сайте zafarmirzo.com (далее — «Сайт»).</p>

          <section>
            <h2>1. Общие положения</h2>
            <p>1.1. Использование Пользователем Сайта и Сервисов регулируется настоящим Соглашением.</p>
            <p>1.2. К настоящему Соглашению и отношениям между Сайтом и Пользователем, возникающим в связи с использованием Сайта и Сервисов, применяется законодательство Российской Федерации.</p>
            <p>1.3. Зарегистрировавшись на Сайте либо воспользовавшись возможностью размещения любой информации, либо каких-либо материалов, какого, либо характера (далее — Информация) на Сайте, просмотра информации на Сайте или любой иной функциональной возможностью Сайта или Сервисом, Пользователь выражает свое безоговорочное согласие со всеми условиями настоящего Соглашения и обязуется их соблюдать. В случае отсутствия у Пользователя безоговорочного согласия со всеми условиями настоящего Соглашения Пользователь обязуется незамедлительно прекратить использование Сайта и Сервисов.</p>
          </section>

          <section>
            <h2>2. Предоставление, размещение и использование информации</h2>
            <p>2.1. Полный доступ к Сайту и Сервисам, в том числе создание профиля Пользователя, размещение Информации, отправка сообщений, возможен только для зарегистрированного Пользователя.</p>
            <p>2.2. Пользователь самостоятельно отвечает за сохранность своих логина и пароля и не имеет права передавать или предоставлять их третьим лицам. Все действия, совершенные на Сайте с использованием принадлежащих Пользователю логина и пароля, считаются совершенными Пользователем.</p>
            <p>2.2. Пользователь обязуется при размещении Информации и ином использовании Сервиса соблюдать следующие условия и ограничения:</p>
            <p>2.2.1. Информация (включая любые материалы), размещаемая Пользователем, не должна нарушать авторские права, права на товарные знаки, средства индивидуализации и/или права на иные объекты интеллектуальной собственности, принадлежащие третьим лицам;</p>
            <p>2.2.2. На Сайте и при использовании Сервисов запрещается совершение действий, направленных на введение других пользователей в заблуждение;</p>
            <p>2.2.3. В рамках Сайта запрещается размещение Информации и комментариев, нарушающих права и законные интересы третьих лиц (в том числе материалов рекламного, эротического, порнографического или оскорбительного характера).</p>
            <p>2.2.4. В рамках Сайта запрещается:</p>
            <ul>
              <li>Пропаганда насилия, расовая, половая, национальная, религиозная дискриминация;</li>
              <li>Ведение коммерческой деятельности в любой форме, в т.ч.: рекламировать товары и услуги, интернет-ресурсы и другие продукты, в том числе в никах, аватарах, подписях. Запрещена любая переписка с другими пользователями посредством комментариев с целью получения коммерческой выгоды;</li>
              <li>Размещение на Сайте иной информации, которая, по мнению администрации сайта, не соответствует миссии, ценностям, тематической политике и целям портала;</li>
              <li>
                Аватар пользователя является публичной информацией и не должен:
                <ul>
                  <li>содержать нецензурные изображения;</li>
                  <li>содержать элементы фирменного стиля сайта;</li>
                  <li>содержать рекламу;</li>
                  <li>копировать аватары других пользователей;</li>
                  <li>содержать информацию или изображения, которые, по мнению администрации сайта, не соответствует политике и ценностям портала</li>
                </ul>
              </li>
              <li>Неуважительное отношение к собеседникам: грубый переход на личности (указание на профессию, национальность, занимаемую должность, физические пороки, психические расстройства, качественная оценка личности собеседника, грубая характеристика его действий, негативное обсуждение личных качеств и иные формы).</li>
              <li>Использование одним Пользователем более одного профайла на Сайте, в том числе для обхода временной блокировки профайла, голосования за свои цитаты, комментарии и др.</li>
            </ul>
            <p>Администрация Сайта в лице модераторов оставляет за собой право удалять подобные материалы и блокировать или удалять профайлы их авторов.</p>
            <p>2.2.5. Действия Пользователя не должны нарушать прав граждан, других пользователей Сайта, в том числе право на неприкосновенность частной жизни, личную и семейную тайну, честь, достоинство и доброе имя.</p>
            <p>2.3. Пользователь соглашается с тем, что размещаемая им на Сайте Информация, доступна для других пользователей, как непосредственно на Сайте, так и путем трансляции в группы в социальных сетях, связанные с Сайтом, с указанием авторства, в том объеме, в котором Пользователь оставил Информацию на Сайте.</p>
            <p>2.4. Пользователь соглашается, что Информация (любая её часть), доступ к которой не закрыт или не ограничен, считается общедоступной и не требует соблюдения режима ее конфиденциальности.</p>
            <p>2.5. Пользователь соглашается с тем, что, размещая или распространяя при помощи Сайта либо его Сервисов любую Информацию, он позволяет её использовать Сайту и пользователям Интернета (в том числе незарегистрированным пользователям Сайта) в соответствии с настоящим Соглашением безвозмездно, бессрочно, если такое использование не противоречит законодательству РФ. При этом Пользователь должен самостоятельно оценивать все риски, связанные с использованием информации, размещенной другими пользователями, включая оценку их достоверности, полноты или полезности.</p>
            <p>2.6. Пользователь соглашается с тем, что Администрация Сайта вправе разрешить третьим лицам, в том числе издателям и рекламным сетям, размещать рекламу на Сайте. В связи с этим Пользователь соглашается с тем, что любое из таких третьих лиц вправе размещать куки-файлы на компьютере Пользователя с целью идентификации компьютера Пользователя каждый раз, когда эти компании посылают Пользователю онлайн-рекламу. При этом у Пользователя имеется понимание того, что Администрация Сайта не контролирует использование куки-файлов Пользователя третьими лицами при размещении рекламы. Пользователь вправе запретить использование его личной информации третьими лицами для распространения рекламы. Такой запрет не влияет на возможность просмотра рекламных сообщений на Сайте.</p>
          </section>
          <section>
            <h2>3. Ответственность</h2>
            <p>3.1. Пользователь самостоятельно несет ответственность перед третьими лицами за свои действия при использовании Сайта и Сервисов, в том числе за то, что они соответствуют требованиям законодательства и не нарушают права и законные интересы третьих лиц. Пользователь самостоятельно и за свой счет обязуется урегулировать все претензии третьих лиц, связанные с действиями Пользователя при использовании Сервиса.</p>
            <p>3.2. Сайт имеет право без уведомления по собственному усмотрению удалить полностью или частично размещенную Пользователем Информацию, в частности, в случае размещения Пользователем на Сайте Информации, комментариев или совершения иных действий, не соответствующих требованиям настоящего Соглашения.</p>
            <p>3.3. За нарушение положений настоящего Соглашения и иных применимых документов, может блокировать доступ Пользователя к Сайту и Сервису и/или удалить профиль Пользователя без предварительного уведомления.</p>
            <p>3.4. Сайт не несет ответственности за использование третьими лицами Информации, размещенной Пользователем на Сайте, включая её воспроизведение и распространение, осуществленные как в рамках Сайта и Сервиса, так и иными возможными способами.</p>
            <p>3.5. Сайт не проверяет, не изменяет и не принимает на себя обязательств по контролю содержания информации, размещаемой пользователями в рамках Сайта и Сервисов, и не гарантирует и не несет ответственности за её достоверность, законность, качество, а также степень соответствия информации конкретным запросам и потребностям пользователей Сервиса.</p>
            <p>3.6. Сайт не несёт ответственности за содержание не принадлежащих Сайту, ссылки на которые могут присутствовать в составе Информации и не гарантирует их доступности, корректной работы и соответствия заявленной тематике.</p>
            <p>3.7. Сайт не возмещает никакой ущерб, прямой или косвенный, причиненный Пользователю или третьим лицам в результате использования или невозможности использования Сайта и/или Сервисов.</p>
          </section>
          <section>
            <h2>4. Прочие условия</h2>
            <p>4.1. Сервис предоставляется Сайтом «как есть». Сайт не гарантирует соответствие информации и Сервисов целям и ожиданиям Пользователя, их бесперебойную и безошибочную работу, а также сохранность профиля Пользователя, размещенных Пользователем на Сайте Информации.</p>
            <p>4.2. Все претензии, связанные с использованием/невозможностью использования Сайта и/или Сервисов, должны направляться по электронной почте:<a href="mailto:zafarmirzo.com">zafarmirzo.com</a></p>
            <p>4.3. Сайт вправе в любое время без уведомления Пользователя изменять текст настоящего Соглашения и/или любые иные условия использования Сайта и Сервисов. В ситуациях, не описанных настоящим Соглашением, администрация имеет право действовать по своему усмотрению.</p>
          </section>
        </div>
      </div>

      <button class="modal__close" type="button" title="Закрыть окно">
        <svg width="11" height="10">
          <use xlink:href="/images/stack.svg#close" />
        </svg>
      </button>
    </div>
  </section>
`;
